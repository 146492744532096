import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export const statusSlice = createSlice({
  name: 'status',

  initialState: {
  
    userProjectTab: 'details',
    


  }, // end Initial State

  reducers: {




    updateUserProjectTab: (state, action) => {
      state.userProjectTab = action.payload
                   
    },
 
 

  }, //end reducers

}) // end slice statusSlice 


// --- Export actions ---------------------------------------------

export const { 

  updateUserProjectTab

  } = statusSlice.actions



// --- Export selectors ------------------------------------------

export const selectStatus = state => state.status //Sample site
 


// --- default Export reducers -----------------------------------

export default statusSlice.reducer