import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, chitBurgandy } from '../../styles/colors';


// --- MUI
 
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import CircleIcon from '@mui/icons-material/Circle';
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



export default function Pricing(props) {
  return (
    <Container>
      <Wrapper>
      <SectionWrapper>
        <HeaderWrapper>
          <Header>  Basic </Header>
          <PriceWrapper> $35 </PriceWrapper>
        </HeaderWrapper>

        <DetailsWrapper>

          <ItemWrapper> <StyledCircle/> evaluation </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>certification (if success) </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>private posting access </ItemWrapper>
          <ItemWrapper> <StyledCircle/> public posting (opt in) </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>course materials access </ItemWrapper>

        </DetailsWrapper>
      </SectionWrapper>

      <SectionWrapper>
      <HeaderWrapper>
          <Header>  Premium </Header>
          <PriceWrapper> $55 </PriceWrapper>
        </HeaderWrapper>

        <DetailsWrapper>

          <ItemWrapper>  <StyledCircle/>All Basic + </ItemWrapper>
          <ItemWrapper> <StyledCircle/>Coaching </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>3 group zoom calls </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>3 message chats </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>public posting (opt in) </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>course materials access </ItemWrapper>

        </DetailsWrapper>
      </SectionWrapper>

      <SectionWrapper>
      <HeaderWrapper>
          <Header>  Add ons </Header>
          <PriceWrapper> --- </PriceWrapper>
        </HeaderWrapper>

        <DetailsWrapper>

          <ItemWrapper>  <StyledCircle/>1 on 1 zoom session - $45 ea </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>Additional group zoom - $45 </ItemWrapper>
          <ItemWrapper>  <StyledCircle/>Additional message chats - $15 </ItemWrapper>

          <ItemWrapper>  <StyledCircle/>Project retake - $25 </ItemWrapper>
        
          <ItemWrapper> <StyledCircle/> Mentor packages - market price</ItemWrapper>
           

        </DetailsWrapper>
      </SectionWrapper>


      </Wrapper>
    </Container>
  );
}
 

  const Container= styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginTop: '.1rem',
    position: 'relative',
    backgroundColor: 'black' ,
    width: '100%',
    height: '100%',
 
    color: 'black',
  //  overflowY: 'auto',
  //  overflowX: 'hidden',
    [theme.breakpoints.down('sm')] : {
                                
      alignItems: 'flex-start',                           
      width: '100%',
      padding: '0',
     
   
   }
  
  })

  
  const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    backgroundColor: 'white' ,
    paddingTop: '.5rem',
    width: '80%',
    // height: '100%',
 
    color: 'black',
  //  overflowY: 'auto',
  //  overflowX: 'hidden',
    [theme.breakpoints.down('md')] : {
                          
      alignItems: 'flex-start',                           
      width: '100%',
   
     
   
   }
  
  })


  const SectionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
   
    width: '33%',
    height: '100%',
    margin: '1rem',
    backgroundColor: 'orange',
  
    [theme.breakpoints.down('md')]: {
      // height: '1.25rem',
      margin: '.1rem',
    },
  })
  

  const HeaderWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    
    width: '100%',
  
 
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  })
  const Header  = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
   
    width: '100%',
    backgroundColor: 'orange',
 
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  })
  const PriceWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
   
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid grey',
 
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  })

  const DetailsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem',
    width: '100%',
   
  backgroundColor: 'white',
   
  
    fontSize: '.8rem',
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  })
  
  const ItemWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1rem',
    height: '1.5rem',
    width: '100%',
   
  // backgroundColor: 'yellow',
   
  
    fontSize: '.8rem',
  
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
  
    },
  })
  

  

  
  const StyledCircle = styled(CircleIcon)({
    fontSize: '.7rem',
    marginRight: '1rem',
      
    [theme.breakpoints.down('sm')]: {
      fontSize: '.55rem',
  
    },
  })