
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, chitDullYellow } from '../styles/colors';
// --- MUI
import triangleSvg from '../images/triangleSvg.svg'
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




export default function Main(props) {

  const navigate = useNavigate()

  const handleClick = (page)=>{

    navigate(`/main/${page}`)
  }


  return (
    <Container>

      <TopWrapper>

      <NavWrapper>
        <TopRow> 
          <NavSelected   > Home </NavSelected>
          <Nav  onClick = {()=>handleClick('userHome')} > User </Nav>
          <Nav onClick = {()=>handleClick('mentorHome')} > Mentors</Nav>
          <Nav onClick = {()=>handleClick('businessHome')} > Business</Nav>
          <Nav onClick = {()=>handleClick('adminHome')} > Admin</Nav>
          </TopRow>
          <BottomRow>
          <Nav   onClick = {()=>handleClick('pricing')} > Pricing </Nav>
     

          <Nav onClick = {()=>handleClick('projectPage')} > Project Detail</Nav>
          <Nav onClick = {()=>handleClick('userProjectPage')} > User Project</Nav>
          <Nav onClick = {()=>handleClick('analysis')} > Analysis Doc</Nav>
          </BottomRow>
        </NavWrapper>

      </TopWrapper>
      <BottomWrapper>
        
        <Disclaimer>All concepts and materials presented here are the property of  Len Schutzman</Disclaimer>
    
      </BottomWrapper>
      <Wrapper>

        <Header>Lenco</Header>
        <Tagline>Virtual Internships</Tagline>
        <SubTagline>Real experience that you can show others</SubTagline>

        <NavButtonWrapper>
          <StyledButton onClick = {()=>handleClick('userHome')} > Users Portal</StyledButton>
          <StyledButton onClick = {()=>handleClick('mentorHome')} > Mentors Portal</StyledButton>
          <StyledButton onClick = {()=>handleClick('businessHome')}> Business Portal</StyledButton>
          <StyledButton onClick = {()=>handleClick('adminHome')}> Admin Portal</StyledButton>
        </NavButtonWrapper>

        <LogoWrapper>
          <MessageWrapperLeft>
            <div> Want that promotion ?</div>
            <div> Want that new job ? </div>
            <div> Prove you can do the job requirements. </div>

          </MessageWrapperLeft>

          <ImageStyle src={triangleSvg} alt="Chit Git Logo" />




          <MessageWrapperLeft>


            <div> Do it to prove it. </div>
            <div> Do it to learn how to do it. </div>
            <div> With help. </div>
          </MessageWrapperLeft>
        </LogoWrapper>


      </Wrapper>
    </Container>
  );
}

// ==================================================


const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  // backgroundColor: chitOrange ,

  background: 'linear-gradient(180deg, rgba(255,107,14,1) 0%, rgba(208,125,9,1) 100%)',


  height: '100vh',
 
  fontFamily: 'Lato, sans-serif',
 overflowY: 'auto',
 overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }


// backgroundColor: testColors.testGreen

})


const Wrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxHeight: 'calc(100%-10rem)',
  width: '100%',
 
  marginTop: '5rem',

  [theme.breakpoints.down('sm')] : {
     
  
  }
  
  })

  const TopWrapper= styled('div')({
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '5rem',
    borderBottom: '2px solid orange',
    
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })

    const NavWrapper= styled('div')({

      display: 'flex',
      flexDirection: 'column',
      
     justifyContent: 'space-between',
     alignItems: 'center',
    
     
    color: bodyBlue,
    fontSize: '.85rem',

    '& div' :{
      margin: '0 1rem'
    },


    
     [theme.breakpoints.down('sm')] : {
     
     }
    
    })

    const Header= styled('div')({
 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      fontSize: '3rem',
      marginTop: '1rem',
      
      [theme.breakpoints.down('sm')] : {
         
      
      }
      
      })



      const Tagline= styled('div')({
        display: 'flex',
      
        justifyContent: 'center',
        alignItems: 'center', 
        width: '60%',
        color: 'white',
        fontSize: '1.3rem',
 
        [theme.breakpoints.down('sm')] : {
          width: '100%'
        }
      })

      const SubTagline= styled('div')({
        display: 'flex',
      
        justifyContent: 'center',
        alignItems: 'center', 
        width: '60%',
        color: shadowBlue,
        fontSize: '1rem',
marginBottom: '2rem',
        [theme.breakpoints.down('sm')] : {
          width: '100%'
        }
      })

    const BottomWrapper= styled('div')({
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '5rem',
      borderTop: '2px solid orange',
      
      [theme.breakpoints.down('sm')] : {
         
      
      }
      
      })

const LogoWrapper= styled('div')({

   display: 'flex',

  justifyContent: 'space-around',
  alignItems: 'center',

  width: '100%',
  backgroundColor: 'white',
  color: 'white',
  margin: '2rem, 0',
  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column'
  }

})

const NavButtonWrapper= styled('div')({

  display: 'flex',

 justifyContent: 'space-around',
 alignItems: 'center',

 width: '100%',
padding: '.5rem 0',

 [theme.breakpoints.down('sm')] : {
 
 }

})

const MessageWrapperLeft= styled('div')({
 
  display: 'flex',
  flexDirection: 'column',
 justifyContent: 'center',
 alignItems: 'center',

 
 
color: 'black',
 [theme.breakpoints.down('md')] : {
  fontSize: '.8rem'
 }

})



const ImageStyle= styled('img')({
  
  height: '16rem',
 
  [theme.breakpoints.down('md')] : {
    height: '10rem'
 }
  

 

})

const ButtonsWrapper= styled('div')({


 
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',
   
  width: '60%',
  


  [theme.breakpoints.down('sm')] : {
    width: '100%'
  }
})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: 'black',
  margin: '1.2rem',
  // width: '5rem',
  padding: '0 1rem',
   
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  },

  [theme.breakpoints.down('md')] : {
   fontSize: '.7rem'
  }

})

const StyledAnchor = styled('a')({
 
  fontSize: '.9rem',
  cursor: 'pointer',
  color: 'blue',

  '&:visited' :{color: 'blue'} 

})



const TopRow= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: bodyBlue,
 fontSize: '.85rem',

 '& div' :{
   margin: '0 1rem'
 },

 
})

const BottomRow= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: bodyBlue,
 fontSize: '.85rem',

 '& div' :{
   margin: '0 1rem'
 },

 
})

const Nav= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
  cursor: 'pointer',
  
 color: bodyBlue,
 fontSize: '.85rem',

 '&:hover' :{
   color: chitDullYellow
 },

 
 [theme.breakpoints.down('md')] : {
  fontSize: '.7rem'
 },

  
 
})

const NavSelected= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: 'white',
 fontSize: '.85rem',

 
 [theme.breakpoints.down('md')] : {
  fontSize: '.75rem'
 },

 

 
})
const Disclaimer= styled('div')({

  marginTop: '.5rem',
  color: 'white',
  fontSize: '.9rem',
  [theme.breakpoints.down('md')] : {
   fontSize: '.75rem'
  },
  
 
  
 })