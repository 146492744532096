import React from 'react';
 
import { chitBurgandy, chitOrange, chitYellowMedium } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function DetailsPrimaryMarket(props) {
  return (
    <Wrapper>
      <Header> Project: <div>Primary Research</div></Header>

      <Goal> The goal of this project is to find where to best spend  advertising dollars for an existing product.
        Take an existing product you use and are familiar with such as soft drinks or tennis racquets.  
</Goal>

<Title>  To Do:   </Title>
      <Description> 
             
        
        <div>
        Create a questionnaire.<br/>
        Use the questionnaire to survey at least 25 people. <br/>
        Create a statistical profile of the data.<br/>
        Make recommendations on where best to spend advertising dollars <br/>
        </div>
      </Description>

      <Title> Output:   </Title>

      <Description> <div> 
        Presentation of findings. <br/>
        Written - 3 page max <br/>
        PowerPoint <br/>
        bonus for messaging recommendations
        </div>
        </Description>

        <Title> Prerequisites: </Title>
        <Description>  <div> 
        statistics beginner <br/>
        presentation software<br/>
         
        </div>
        </Description>
  
        <Title> Materials suggestion: </Title>
        <Description>  <div> 
          "Ogilvy on Advertising" by Ogilvy <br/>

         
        </div>
        </Description>

    </Wrapper>
  );
}

// ---------------------------

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  

  width: '90%',

  fontSize: '.85rem',
 
  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
 
    fontSize: '.65rem',
  },
})

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  width: '98%',
   
  fontSize: '.9rem',

  '& div': {
    marginLeft: '2rem',
    color: 'black',
    fontSize: '.9rem',

    [theme.breakpoints.down('md')]: {
      // height: '1.25rem',
      
      fontSize: '.8rem',
    },
  },
})

  const Goal = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
 
  
    width: '98%',
     
  
      marginLeft: '2rem',
      color: 'black',
      fontSize: '.9rem',
 
      [theme.breakpoints.down('md')] : {
        marginLeft: '.5rem',      
        fontSize: '.65rem',
    
     
     }
})

const Description = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  width: '98%',
   marginTop: '.3rem',
   fontSize: '.85rem',

  '& div': {
    marginLeft: '2rem',
    color: 'black',
    fontSize: '.85rem',

    [theme.breakpoints.down('md')] : {
                              
      fontSize: '.65rem',
  
   
   }
  },
})

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  width: '50%',
   
  fontSize: '.9rem',

 
})

