
import React from 'react';
 // --- MUI
 
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
import { chitBurgandy } from '../../../styles/colors';
const theme = createTheme(); // allows use of mui theme in styled component

 
// ===================================

export default function SponsorChallenge(props) {
  return (
    <Container>
      <Title> Heinz data analyst challenge </Title>

      <DetailWrapper> 

      <Header>Project overview: </Header>
        <Details>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</Details>
        
        </DetailWrapper>

        <DetailWrapper> 
      <Header>Prerequisites: </Header>
        <Details>Intermediate python <br/> beginner D3.js </Details>
        
        </DetailWrapper>

        <DetailWrapper> 
      <Header>Details: </Header>
        <Details>
          Start: Nov 6 <br/> 
          Due: Nov 24 <br/> 
          Mentored by Heinz employees <br/> 
          12 (of 25) challengers still available <br/> 
        
        </Details>
        
        </DetailWrapper>
      
    </Container>
  );
}

// ---------------------------------

const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',

  width: '40vh',
  height: '100%',
  color: 'white',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})



const Title= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: chitBurgandy ,
  width: '100%',
  marginBottom: '8px',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const DetailWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '100%',
  fontSize: '.85rem',
  color:'black',
  marginBottom: '8px',
 

  [theme.breakpoints.down('sm')] : {
                              

 }

})

const Details= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
marginLeft: '.5rem',
 

  [theme.breakpoints.down('sm')] : {
                              

 }

})

const Header= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
 
  width: '100%',
  fontSize: '.85rem',
  color: chitBurgandy,
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }

})