import React from 'react';
 
import { chitBurgandy, chitOrange, chitYellowMedium, lightGrey, mediumGrey } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function Mentor(props) {
  return (
    <Wrapper>
      <SectionWrapper>
      <SectionRowPackage>
      <div> Coach - Ray Donovan</div>
      <div> Account manager - East Coast Advertising</div>
      <Bio> view full bio </Bio>
      </SectionRowPackage>
      </SectionWrapper>

      <SectionWrapper>
       
        <SectionRowWrapper> 
      <SectionWrapperLeft> 
    
        <SectionSubHeader> Group Zooms</SectionSubHeader>
        <Notice> Questions must be submitted 24 hours in advance</Notice>
        <div>
          <div>Oct - Nov Project Session</div>
          <ul> 
          <li>Thur, November 3 7PM EST</li>
          <li>Thur, November 10 7PM EST</li>
          <li>Thur, November 17 7PM EST</li>
          </ul>
        </div>
        <div>Dec- Jan Project Session - TBD </div>

      </SectionWrapperLeft>
      <SectionWrapperRight>
     <div>  
      <StyledButton> Submit Zoom Question</StyledButton>
      </div> <div> 
      <StyledButton> Send a chat message</StyledButton>
      </div>
      </SectionWrapperRight>
       

      </SectionRowWrapper>
      </SectionWrapper>

      <SectionWrapper>
        <SectionRowPackage>  
        <SectionSubHeader> Your current package - basic</SectionSubHeader>
        <div> You have 1 group zoom and 2 chats remaining</div>
        <div> 
        <div>Upgrade to premium 
        <StyledButton> Upgrade</StyledButton>
        </div>
        </div>
       </SectionRowPackage>
      </SectionWrapper>
     

    </Wrapper>
  );
}

// ---------------------------

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  width: '75%',
 
backgroundColor: 'white',
  

  fontSize: '.85rem',

 


  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
    width: '95%',
  },
})

const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
 
  width: '100%',
  flexWrap: 'wrap',
backgroundColor: 'orange',
   borderBottom: '1px solid grey',
  padding: '.5rem',
  fontSize: '.85rem',

  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
    padding: '.25rem',

  },
})

const SectionRowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '1rem',
  width: '100%',
 
backgroundColor: 'white',
 

  fontSize: '.85rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const SectionRowPackage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '1rem',
  width: '100%',
 
backgroundColor: 'white',
 

  fontSize: '.85rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const SectionWrapperLeft = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  // padding: '6px 6px',
  width: '50%',
  flexWrap: 'wrap',
backgroundColor: 'white',
   borderRight: '1px solid grey',

  fontSize: '.85rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const SectionSubHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  // padding: '6px 6px',
  // width: '100%',
  flexWrap: 'wrap',
 color: chitBurgandy,

  '& em':{
    fontWeight: 'bold'
  },

  fontSize: '.85rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const SectionWrapperRight = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // padding: '6px 6px',
  width: '49%',
  height: '100%',
  flexWrap: 'wrap',
backgroundColor: 'white',
 
'& div':{
  margin: '1rem',

  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
    margin: '1rem 0',
  },
},
  fontSize: '.85rem',

  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',

  },
})

const Bio = styled('div')({
 color: 'blue',
 textDecoration: 'underline'
})

const Notice = styled('div')({
  fontSize: '.65rem',
  fontWeight: 'bold',
  marginBottom: '1rem 0',
  color: 'blue'
 })

 const StyledButton= styled(Button)({
  backgroundColor: mediumGrey,
  border: '1px solid #E6E7E8',
  color: 'white',
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  textTransform: 'none',
  cursor:'inherit',

  
  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',

    fontSize: '.65rem',
  },

  '&:hover' : {backgroundColor: mediumGrey}
 
})