import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, mediumGrey, chitBurgandy, mediumMediumGrey } from '../../styles/colors';


import HomeHeader from './components/UserHomeHeader';
import SponsorChallenge from './components/SponsorChallenge';
// --- MUI
 
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



export default function UserHome(props) {

  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);



  const handleClose = () => {
    setOpen(false);
  };





  return (
    <Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <SponsorChallenge />
        </DialogContent>
        <DialogActions>


          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleClose}
          >
            Apply
          </StyledButton>

          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleClose}
          >
            Cancel
          </StyledButton>

        </DialogActions>
      </Dialog>


      <HomeHeader />


      <ProjectWrapper>
        <ProjectHeader>

          <div>      Find a new project   </div>

          <Search>
            <SearchIcon />
          </Search>

        </ProjectHeader>

        <DisciplineWrapper>
          <Discipline> Finance</Discipline>
          <Discipline> Sales</Discipline>
          <Marketing onClick={() => navigate(`/main/projectPage`)}> Marketing</Marketing>

          <Discipline> Discipline</Discipline>
          <Discipline> Discipline</Discipline>
          <Discipline> Discipline</Discipline>
          <Discipline> etc</Discipline>
        </DisciplineWrapper>
      </ProjectWrapper>


      <SponsorWrapper>
        <SponsoredHeader>Check out new Sponsored Projects </SponsoredHeader>
        <DisciplineWrapper>

          <Sponsor> Gucci</Sponsor>

          <SponsorHighlighted onClick={() => setOpen(true)}>
            Heinz
          </SponsorHighlighted>


          <Sponsor> Google</Sponsor>
          <Sponsor> Sponsor</Sponsor>
          <Sponsor> Sponsor</Sponsor>
          <Sponsor> etc</Sponsor>
        </DisciplineWrapper>
      </SponsorWrapper>

      <SponsorWrapper>
        <SponsoredHeader>Recently completed projects by others </SponsoredHeader>
        <DisciplineWrapper>


          <Existing> Data display challenge</Existing>
          <Existing> Questionnaire challenge</Existing>

          <Existing> Sales challenge </Existing>
          <Existing> challenge</Existing>
          <Existing> challenge</Existing>
          <Existing> etc</Existing>
        </DisciplineWrapper>
      </SponsorWrapper>


    </Container>
  );
}
 

const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
 
  width: '100%',
  height: '100%',
 

//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const ProjectWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2px',
  width: '100%',
  height: '8rem',
  color: 'black',
  backgroundColor: 'white',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})





const ProjectHeader= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '80%',
  margin: '0 1rem',
  
   
  color: chitBurgandy,
  

  [theme.breakpoints.down('md')] : {
    width: '100%',                        
 }
})

const Search = styled('div')({
  display: 'block',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  border: '1px solid grey',
  width: '10rem',
  borderRadius: '5px',
  paddingTop: '4px',
  height: '2rem',
  

  [theme.breakpoints.down('md')] : {
                            
    height: '1.5rem',
 }
})

const SponsorWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2px',
  width: '100%',
  height: '12rem',
  paddingTop: '1rem',
  color: 'black',
  backgroundColor: 'white',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
    height: '8rem',                       
     
   
 
 }
})


const SponsoredHeader= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
  marginBottom : '12px',
  
   
  color: chitBurgandy,
  

  [theme.breakpoints.down('sm')] : {
                            
 }
})


const Sponsor= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  padding: '2px',
  margin: '0 1rem 0 0 ',
  height: '5rem',
  width: '6rem',
  border : '1px solid grey',
   
   
  color: mediumMediumGrey,
  

  [theme.breakpoints.down('md')] : {
    height: '3rem',
    width: '4rem',    
    fontSize: '.9rem'          
 }
})

const SponsorHighlighted= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
 
  margin: '0 1rem 0 0 ',
  height: '5rem',
  width: '6rem',
  border : '3px solid red',
  backgroundColor: 'red',
  color: 'white',
  fontSize: '1.1rem',
 
  '&:hover':{
    backgroundColor: mediumGrey,
    border : '3px solid #727376',
  },

  [theme.breakpoints.down('md')] : {
    height: '3rem',
    width: '4rem',    
    fontSize: '.9rem'          
 }
})

const Existing= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  padding: '2px',
  margin: '0 1rem 0 0 ',
  height: '5rem',
  width: '8rem',
  border : '1px solid grey',
  fontSize: '.85rem',
   textAlign: 'center',
   
  color: mediumMediumGrey,
  [theme.breakpoints.down('md')] : {
    height: '3rem',
    width: '6rem',    
    fontSize: '.65rem'          
 },
  
  [theme.breakpoints.down('sm')] : {
    height: '3rem',
    width: '6rem',    
    fontSize: '.65rem'          
 }
})




const DisciplineWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
   
   
  color: 'black',
  

  [theme.breakpoints.down('md')] : {
            width: '100%',
            justifyContent: 'center',
 }

})

const Discipline= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  padding: '2px',
  margin: '0 1rem 0 0 ',
   
   fontSize: '.9rem',
  color: mediumMediumGrey,
  

  [theme.breakpoints.down('md')] : {
                            
 }
})


const Marketing= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  padding: '8px',
  margin: '0 1rem',
   
  backgroundColor: 'red',
  borderRadius: '5px',
   
  color: 'white',
  cursor: 'pointer',

  '&:hover':{
    backgroundColor: mediumGrey,
  },

  [theme.breakpoints.down('sm')] : {
      padding: '4px'              
 }
})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  textTransform: 'none',
  '&:hover' :{
    backgroundColor: lightGrey
  }
})