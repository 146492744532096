
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, chitDullYellow, mediumLightGrey } from '../styles/colors';
// --- MUI
import triangleSvg from '../images/triangleSvg.svg'
import Button from '@mui/material/Button'

import Admin from '../pages/public/AdminHome';
import Business from '../pages/public/BusinessHome';
import User from '../pages/public/UserHome';
import Mentor from '../pages/public/MentorHome';

import UserProjectPage from '../pages/public/UserProjectPage';
import ProjectPage from '../pages/public/ProjectPage';
import Analysis from './public/components/Analysis';
import Pricing from '../pages/public/Pricing';


import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




export default function Main(props) {

  const navigate = useNavigate()
  const match = useParams()
  let page = match.page

  console.log('[ Main ] Page Match ', match.page);

  const handleClick = (page)=>{

    navigate(`/main/${page}`)
  }


  return (
    <Container>

      <TopWrapper>

      <NavWrapper>
        <TopRow> 
            {page === '' &&
              <NavSelected onClick={() => handleClick('home')} > Home </NavSelected>
            }

            {page !== '' &&
              <Nav onClick = {()=>handleClick('home')} > Home </Nav>
            }

            {page === 'userHome' &&
             <NavSelected  onClick = {()=>handleClick('userHome')} > User  </NavSelected>
            }
            {page !== 'userHome' &&
              <Nav  onClick = {()=>handleClick('userHome')} > User </Nav>
            }

{page === 'mentorHome' &&
             <NavSelected  onClick = {()=>handleClick('mentorHome')} > Mentors  </NavSelected>
            }
            {page !== 'mentorHome' &&
              <Nav onClick = {()=>handleClick('mentorHome')} > Mentors  </Nav>
            }


{page === 'businessHome' &&
             <NavSelected  onClick = {()=>handleClick('businessHome')} > Business Home</NavSelected>
            }
            {page !== 'businessHome' &&
              <Nav onClick = {()=>handleClick('businessHome')} > Business  </Nav>
            }

{page === 'adminHome' &&
             <NavSelected  onClick = {()=>handleClick('adminHome')} > Admin  </NavSelected>
            }
            {page !== 'adminHome' &&
              <Nav onClick = {()=>handleClick('adminHome')} > Admin </Nav>
            }
       
          </TopRow>
        

          <BottomRow> 


            
{page === 'pricing' &&
             <NavSelected  onClick = {()=>handleClick('pricing')} > Pricing </NavSelected>
            }
            {page !== 'pricing' &&
              <Nav onClick = {()=>handleClick('pricing')} > Pricing</Nav>
            }
       
       {page === 'projectPage' &&
             <NavSelected  onClick = {()=>handleClick('projectPage')} > Project </NavSelected>
            }
            {page !== 'projectPage' &&
              <Nav onClick = {()=>handleClick('projectPage')} > Project</Nav>
            }
       
       {page === 'userProjectPage' &&
             <NavSelected  onClick = {()=>handleClick('userProjectPage')} > User Project </NavSelected>
            }
            {page !== 'userProjectPage' &&
              <Nav onClick = {()=>handleClick('userProjectPage')} > User Project</Nav>
            }
     
       
     {page === 'analysis' &&
             <NavSelected  onClick = {()=>handleClick('analysis')} > Analysis Docs </NavSelected>
            }
            {page !== 'analysis' &&
              <Nav onClick = {()=>handleClick('analysis')} > Analysis Docs</Nav>
            }
     
 
          </BottomRow>
        </NavWrapper>

      </TopWrapper>

      <BottomWrapper>
        
        <Disclaimer>All concepts and materials presented here are the property of  Len Schutzman</Disclaimer>
       
      </BottomWrapper>
       
      <Wrapper>

        {page ==='userHome' && <User/>}
        {page ==='mentorHome' && <Mentor/>}
        {page ==='businessHome' && <Business/>}
        {page ==='adminHome' && <Admin/>}

        {page ==='pricing' && <Pricing/>}
        {page ==='projectPage' && <ProjectPage/>}
        {page ==='userProjectPage' && <UserProjectPage/>}
        {page ==='analysis' && <Analysis/>}

      </Wrapper>
    </Container>
  );
}

// ==================================================


const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  // backgroundColor: chitOrange ,

  background: 'linear-gradient(180deg, rgba(255,107,14,1) 0%, rgba(208,125,9,1) 100%)',


  height: '100vh',
 
  fontFamily: 'Lato, sans-serif',
 overflowY: 'auto',
 overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }


// backgroundColor: testColors.testGreen

})


const Wrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // height: 'calc(100% - 10rem)',
  height: 'calc(100% - 8rem)',
  width: '100%',
 
  marginTop: '5rem',

  [theme.breakpoints.down('sm')] : {
     
  
  }
  
  })

  const TopWrapper= styled('div')({
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '5rem',
    borderBottom: '2px solid orange',
    
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })

    const NavWrapper= styled('div')({

      display: 'flex',
      flexDirection: 'column',
      
     justifyContent: 'space-between',
     alignItems: 'center',
    
     
    color: bodyBlue,
    fontSize: '.85rem',

    '& div' :{
      margin: '0 1rem'
    },


    
     [theme.breakpoints.down('sm')] : {
     
     }
    
    })

    const Header= styled('div')({
 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      fontSize: '3rem',
      marginTop: '1rem',
      
      [theme.breakpoints.down('sm')] : {
         
      
      }
      
      })



      const Tagline= styled('div')({
        display: 'flex',
      
        justifyContent: 'center',
        alignItems: 'center', 
        width: '60%',
        color: 'white',
        fontSize: '1.3rem',
 
        [theme.breakpoints.down('sm')] : {
          width: '100%'
        }
      })

      const SubTagline= styled('div')({
        display: 'flex',
      
        justifyContent: 'center',
        alignItems: 'center', 
        width: '60%',
        color: shadowBlue,
        fontSize: '1rem',
marginBottom: '2rem',
        [theme.breakpoints.down('sm')] : {
          width: '100%'
        }
      })

    const BottomWrapper= styled('div')({
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '3rem',
      borderTop: '2px solid orange',
      
      [theme.breakpoints.down('sm')] : {
         
      
      }
      
      })

const LogoWrapper= styled('div')({

   display: 'flex',

  justifyContent: 'space-around',
  alignItems: 'center',

  width: '100%',
  backgroundColor: chitOrange,
  color: 'white',
  margin: '2rem, 0',
  [theme.breakpoints.down('sm')] : {
    width: '33%'
  }

})

const NavButtonWrapper= styled('div')({

  display: 'flex',

 justifyContent: 'space-around',
 alignItems: 'center',

 width: '100%',
padding: '.5rem 10%',

 [theme.breakpoints.down('sm')] : {
 
 }

})

const MessageWrapperLeft= styled('div')({
 
  display: 'flex',
  flexDirection: 'column',
 justifyContent: 'center',
 alignItems: 'center',

 width: '100%',
 
color: 'black',
 [theme.breakpoints.down('sm')] : {
 
 }

})



const ImageStyle= styled('img')({
  
  height: '16rem',
  cursor: 'pointer',
 
  

  '&:hover' :{

    opacity: '.8'
  }

})

const ButtonsWrapper= styled('div')({


 
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',
   
  width: '60%',
  


  [theme.breakpoints.down('sm')] : {
    width: '100%'
  }
})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: 'black',
  margin: '1.2rem',
  // width: '5rem',
  padding: '0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const TopRow= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: bodyBlue,
 fontSize: '.85rem',

 '& div' :{
   margin: '0 1rem'
 },

 
})

const BottomRow= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: bodyBlue,
 fontSize: '.85rem',

 '& div' :{
   margin: '0 1rem'
 },

 
})

const Nav= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  cursor: 'pointer',
 color: bodyBlue,
 fontSize: '.85rem',

 '&:hover' :{
   color: chitDullYellow
 },
 [theme.breakpoints.down('md')] : {
  fontSize: '.75rem'
 },
 
})

const NavSelected= styled('div')({

  display: 'flex',
      
  justifyContent: 'space-around',
  alignItems: 'center',
 
  
 color: 'white',
 fontSize: '.85rem',
 [theme.breakpoints.down('md')] : {
  fontSize: '.75rem'
 },
 

 
})

const Disclaimer= styled('div')({

  marginTop: '.5rem',
 color: 'white',
 fontSize: '.9rem',
 [theme.breakpoints.down('md')] : {
  fontSize: '.75rem'
 },
 

 
})

