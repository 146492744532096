import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export const loginSlice = createSlice({
  name: 'login',

  initialState: {
  
    login: '',
    initialMessage: true


  }, // end Initial State

  reducers: {




    updateLogin: (state, action) => {
      state.login = action.payload
                   
    },
 
 

  }, //end reducers

}) // end slice statusSlice 


// --- Export actions ---------------------------------------------

export const { 

  updateLogin

  } = loginSlice.actions



// --- Export selectors ------------------------------------------

export const selectLogin = state => state.login //Sample site
 


// --- default Export reducers -----------------------------------

export default loginSlice.reducer