import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, mediumGrey, chitBurgandy } from '../../../styles/colors';

import triangleSvg from '../../../images/triangleSvg.svg'

// --- MUI
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ======================================================
export default function BusinessHomeHeader(props) {
  const match = useParams()
  let page = match.page
  let navigate = useNavigate()

  return (
    <Container>
      <Wrapper>

        <ImageWrapper>
          <ImageStyle src={triangleSvg} alt="Chit Git Logo" />
        </ImageWrapper>

        <DetailsWrapper>
          <Top>
            <TopPage> Business Home </TopPage>
            Lenco for Businesses 

          </Top>
          <BenefitsWrapper>

            <ul>
              <li>Find promising prospects who have solved <br/> 
                  problems  that your the position you look to fill face.
                </li>
              <li>Get first look at prospects by sponsoring a public project.</li>
            
              <li>  Have prospects solve your company's specifc problems <br/> by sponsoring a private project.  </li>
              



            </ul>
          </BenefitsWrapper>

        </DetailsWrapper>


      </Wrapper>
    </Container>
  );
}

//--------------------------------------------------
const Container= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
  // height: '15rem',
  color: 'black',
  backgroundColor: 'white',

//  overflowY: 'auto',
//  overflowX: 'hidden',

})



const Wrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  position: 'relative',
  
  width: '80%',
  // height: '15rem',
  color: 'black',
   
 
  [theme.breakpoints.down('md')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 },
 [theme.breakpoints.down('sm')] : {
                              
  flexDirection: 'column',
  

}




})

const DetailsWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
  marginLeft: '2rem',
  
 
  // height: '15rem',
  color: 'black',


//  overflowY: 'auto',
//  overflowX: 'hidden',
[theme.breakpoints.down('md')] : {
                              
  fontSize: '.8rem',


}
})



const ImageStyle= styled('img')({
  
  height: '10rem',
  cursor: 'pointer',
 
  

  '&:hover' :{

    opacity: '.8'
  }

})

const ImageWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  
  width: '12rem',
  height: '100%',
 
  // backgroundColor: 'red',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Top= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  
  width: '100%',
 
  // backgroundColor: 'red',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const TopPage= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  
  color: chitBurgandy,
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }
})





const BenefitsWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
 
  // backgroundColor: 'orange',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Message= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
 
  // backgroundColor: 'grey',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})


const Features= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
 
  // backgroundColor: 'blue',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const LoginWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginLeft: '2rem',
  
  // width: '10rem',
  height: '15rem',
  color: 'black',
  // backgroundColor: 'green',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const LoginButton= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '0 1.5rem',
  margin: '0 1rem',
   
  backgroundColor: lightGrey,
  borderRadius: '5px',
  fontSize: '.9rem',
  color: 'black',
  cursor: 'pointer',
textAlign: 'center',
  '&:hover':{
    backgroundColor: mediumGrey,
  },
  [theme.breakpoints.down('sm')] : {
                            
 }
})