import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, mediumGrey, chitBurgandy } from '../../styles/colors';

import BusinessHomeHeader from './components/BusinessHomeHeader';

// --- MUI
 
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
import { Title } from '@mui/icons-material';
const theme = createTheme(); // allows use of mui theme in styled component



export default function BusinessHome(props) {
  return (
    <Container>
      <BusinessHomeHeader/>

      <SectionWrapper>
        <LeftSectionWrapper>
        <TitleWrapper>Search projects for prospects</TitleWrapper>
          
        </LeftSectionWrapper>

        <RightSectionWrapper>
          
        <ButtonWrapper>
          <BusButton  > Search Projects </BusButton>

        </ButtonWrapper>
        </RightSectionWrapper>

      </SectionWrapper>

      <SectionWrapper>
        <LeftSectionWrapper>
        <TitleWrapper> Sponsor a public project  </TitleWrapper>
          <SyledUl>
            <li> get first look at prospects</li>
            <li> limit and screen applicants</li>
            <li> have your employeeds mentor prospects, or <br/> have Lenco mentor</li>
             
          </SyledUl>
        </LeftSectionWrapper>

        <RightSectionWrapper>
          
        <ButtonWrapper>
          <BusButton  > Sponsor  </BusButton>

        </ButtonWrapper>
        </RightSectionWrapper>

      </SectionWrapper>

      <SectionWrapper>
        <LeftSectionWrapper>
          
          <TitleWrapper> Sponsor a private project  </TitleWrapper>
          <SyledUl>
            <li> get first look at prospects</li>
            
            <li> project details proprietary to your company</li>
            <li> all solutions proprietary to your company</li>
            <li> limit and screen applicants</li>
            <li> have your employeeds mentor prospects, or <br/> have Lenco mentor</li>
            <li>  offer incentives (such as jobs) </li>
          </SyledUl>
        </LeftSectionWrapper>

        <RightSectionWrapper>
          
        <ButtonWrapper>
          <BusButton  > Sponsor  </BusButton>

        </ButtonWrapper>
        </RightSectionWrapper>

      </SectionWrapper>
     
     


 
    </Container>
  );
}
 

const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '100%',
  height: '100%',
  color: 'black',
 overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})


const SectionWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2px',
  width: '100%',
  fontSize: '.85rem',
  color: 'black',
  backgroundColor: 'white',
  //  minHeight: '8rem',
  padding: '1rem 0',
  // borderBottom: '1px solid grey',
  borderTop: '1px solid orange',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
          flexDirection: 'column',                    
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const LeftSectionWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2px',
  width: '65%',
  // fontSize: '.9rem',
  color: 'black',
  backgroundColor: 'white',
   
  '& ul': {
     
    fontSize: '.85rem',
    [theme.breakpoints.down('sm')] : {
                              
      fontSize: '.75rem',
     
   }


  },
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 }
})

const RightSectionWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2px',
  width: '35%',
 
  color: 'black',
  backgroundColor: 'white',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginLeft: '2rem',
  
  // width: '10rem',
 
  color: 'black',
  // backgroundColor: 'green',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const BusButton= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '0 1.5rem',
  margin: '0 1rem',
   
  backgroundColor: lightGrey,
  borderRadius: '5px',
  fontSize: '.9rem',
  color: 'black',
  cursor: 'pointer',
textAlign: 'center',
  '&:hover':{
    backgroundColor: mediumGrey,
  },
  [theme.breakpoints.down('sm')] : {
                            
 }
})

const TitleWrapper= styled('div')({
  color: chitBurgandy
})

const SyledUl= styled('ul')({
  marginLeft: '7rem'
})