import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, mediumGrey, chitBurgandy } from '../../../styles/colors';

import triangleSvg from '../../../images/triangleSvg.svg'
import Countdown from './Countdown'
// --- MUI
 
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ======================================================
export default function UserProjectPageHeader(props) {
  const match = useParams()
  let page = match.page
  let navigate = useNavigate()

  return (
    <Container>
      <Wrapper>

        <Top>
          <TopPage> User Project Page </TopPage>

          <Section>
            <Title> Project: </Title>
            <Message> Secondary market research</Message>
          </Section>
          <Section>
            <Title> Mentor : </Title>
            <Message> Ray Donovan </Message>
          </Section>
          <Section>
            <Title> Next Group Zoom:   </Title>
            <Message>  November x</Message>
          </Section>

          <Section>
            <Title>    Zoom question submission deadline </Title>

            <CountdownWrapper>
              <Countdown />
            </CountdownWrapper>

          </Section>

        </Top>

        <UploadWrapper>
          <UploadButton > Upload Solution  </UploadButton>

        </UploadWrapper>

      </Wrapper>
    </Container>
  );
}

//--------------------------------------------------
const Container= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '1rem 0',
  width: '100%',
 
  color: 'black',
  backgroundColor: 'white',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }
})



const Wrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  
  width: '80%',
 
  color: 'black',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column',                          
    alignItems: 'center',             
    width: '100%',
 
 
 }
})

const Section= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  fontSize: '.9rem',
  width: '80%',
  color: chitBurgandy,
 

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
                          
     fontSize: '.8rem',                     
    width: '100%',
 
   
 
 }
})

const UploadWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
 
  
 
  // height: '15rem',
  color: 'black',
  // backgroundColor: 'green',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})


const UploadButton= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '8px 1.5rem',
  margin: '0 1rem',
   
  backgroundColor: lightGrey,
  borderRadius: '5px',
   
  color: 'black',
  cursor: 'pointer',
textAlign: 'center',
  '&:hover':{
    backgroundColor: mediumGrey,
  },
  [theme.breakpoints.down('sm')] : {
           fontSize: '.6rem'                 
 }
})


const ImageStyle= styled('img')({
  
  height: '10rem',
  cursor: 'pointer',
 
  

  '&:hover' :{

    opacity: '.8'
  }

})

const ImageWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  
  width: '12rem',
  height: '100%',
 
  // backgroundColor: 'red',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Top= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  
  width: '100%',
 
  // backgroundColor: 'red',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const TopPage= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  
  color: chitBurgandy,
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }
})




const Title= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  color: chitBurgandy,
  marginRight: '.5rem',
  // backgroundColor: 'grey',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'flex-start',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Message= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  color: 'grey',
 
  // backgroundColor: 'grey',
   

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})



const CountdownWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '50%',
  height: '100%',
  color: 'black',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})