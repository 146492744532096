import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App'
import { BrowserRouter } from 'react-router-dom'

import reportWebVitals from './reportWebVitals';

//redux imports
import {Provider} from 'react-redux'

import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './app/redux/rootReducer'
const store = configureStore({
  reducer: rootReducer
});

 
 
 

ReactDOM.render(
 

<Provider store = {store}> 
<BrowserRouter>
  <App />
</BrowserRouter>
</Provider> 


 
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



