//rootReducer.js

/*

DATA BASE - Data Loading --------------

Initial Store Data from database gets loaded with
homeActions (features/main/home/homeActions.js) in
componentDidMount.  The Home.jsx file is the destination
after successful login.


*/

import { combineReducers } from 'redux';


import loginReducer from './loginSlice';
import statusReducer from './statusSlice'
// import peopleReducer from '../peopleRedux/peopleSlice'


const rootReducer = combineReducers({

  login: loginReducer,
  // sample: sampleReducer,
    status:  statusReducer,
  //   people: peopleReducer,
  //   categories: categoriesReducer,
  //   groups: groupsReducer,
  //   logHolders: logHoldersReducer,
  //   logs: logsReducer,
  //   notes: notesReducer,
  //   personalChits: personalChitsReducer,
  //   topicalSections: topicalSectionsReducer,
  //   topics: topicsReducer,
  //   twoPartyChits: twoPartyChitsReducer,
  //   firstContacts: firstsReducer,
  //   sharedChits: sharedChitsReducer,
  //   receivedChits: receivedChitsReducer
 
    

  

})

export default rootReducer