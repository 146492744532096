import React from 'react';
 
import { chitBurgandy, chitOrange, chitYellowMedium, lightGrey, mediumGrey } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function Attachments(props) {
  return (
    <>
    
    <UploadWrapper>
          <UploadButton > Add attachment  </UploadButton>
         
          </UploadWrapper>
    
 
    <Wrapper>
  
      <LeftWrapper>  
      <Title> Your attachments:</Title>
      <Section> 
        <Attachment> Completed Questionnaire </Attachment>
        <Attachment>SWOT diagram </Attachment>
        <Attachment> draft presentation</Attachment>

      </Section>

      </LeftWrapper>

      <RightWrapper> 
      <Title> Attachments from your coach.</Title>
      <Section> 
        <Attachment>SWOT diagram reviewed</Attachment>
         

      </Section>

      </RightWrapper>
    </Wrapper>
    </>
  );
}

// ---------------------------

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
 
  width: '75%',

  flexWrap: 'wrap',
backgroundColor: 'white',
   paddingTop: '2rem',
 
  fontSize: '.85rem',
 
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const Section = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
 
  width: '100%',

 
   
 
  fontSize: '.85rem',
 
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

 
   
  fontSize: '.9rem',

 
})

const LeftWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

  width: '50%',
 
  fontSize: '.9rem',

 
})


const RightWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

  width: '50%',
   
  fontSize: '.9rem',

 
})

const Attachment = styled('div')({
  color: 'blue',
  textDecoration: 'underline',
  fontSize: '.9rem',
  paddingLeft: '1rem',
 
 
})

const UploadWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2rem',
  
  
  // height: '15rem',
  color: 'black',
  // backgroundColor: 'green',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const UploadButton= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '0 1.5rem',
  margin: '0 1rem',
   fontSize: '.85rem',
  backgroundColor: lightGrey,
  borderRadius: '5px',
   
  color: 'black',
  cursor: 'pointer',
textAlign: 'center',
  '&:hover':{
    backgroundColor: mediumGrey,
  },
  [theme.breakpoints.down('sm')] : {
                            
 }
})
