
import React from 'react';

import { chitOrange, chitYellowMedium } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




// ======================================================

export default function Note(props) {
 
  return (
    <Container> 
    <NoteWrapper>
      <IconWrapper>
        <StyledEditIcon />
        <StyledDeleteIcon />
      </IconWrapper>

      <Content>
      Lorem ipsum dolor sit amet  <br/>
        <Link>wwww.youtube.com/xyz</Link>
      </Content>


    </NoteWrapper>

    <NoteWrapper>
      <IconWrapper>
        <StyledEditIcon />
        <StyledDeleteIcon />
      </IconWrapper>

      <Content>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  <br/>
        
      </Content>


    </NoteWrapper>

    <NoteWrapper>
      <IconWrapper>
        <StyledEditIcon />
        <StyledDeleteIcon />
      </IconWrapper>

      <Content>
        Another note <br/>
         
      </Content>
    </NoteWrapper>

    <NoteWrapper>
      <IconWrapper>
        <StyledEditIcon />
        <StyledDeleteIcon />
      </IconWrapper>

      <Content>
        Another note <br/>
         
      </Content>
    </NoteWrapper>
    </Container>
  );
}


 // -----------------------------------------------------------------

 const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '6px 6px',
  margin: '6px 6px',

  flexWrap: 'wrap',
 
   
 
  fontSize: '.75rem',
  height: '9rem',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

 const NoteWrapper = styled(Paper)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '6px 6px',
  margin: '6px 6px',

  flexWrap: 'wrap',
backgroundColor: chitYellowMedium,
   
  width: '10rem',
  fontSize: '.75rem',
  height: '9rem',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

 
const IconWrapper= styled('div')({
 position: 'absolute',
 top: '3px',
  display: 'flex',
 
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  // backgroundColor: 'green',
  // width: '50%',

  width: '100%',
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

  const StyledEditIcon= styled(EditIcon)({
    backgroundColor: 'white',
    borderRadius: '5px',
    fontSize: '.9rem',
    color: chitOrange,
    margin: '0 .5rem .3rem .5rem',
    cursor: 'pointer',
    
 
    [theme.breakpoints.down('sm')] : {
      // height: '1.25rem',
      // backgroundColor: 'red'
    },
  })
  
  
  const StyledDeleteIcon= styled(DeleteIcon)({
    backgroundColor: 'white',
    borderRadius: '5px',
    fontSize: '.9rem',
    color: chitOrange,
    margin: '0 .5rem .3rem .5rem',
    cursor: 'pointer',
    
 
    [theme.breakpoints.down('sm')] : {
      // height: '1.25rem',
      // backgroundColor: 'red'
    },
  })

  const Link= styled('span')({
 
    
    color: 'blue',
    textDecoration: 'underline'
 
 
  })

  const Content = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '6px 6px',
 
  
    flexWrap: 'wrap',
 
     
    width: '97%',
    fontSize: '.75rem',
 
   
  
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  })