/* app/App.js in chitgit_2022

Main Funcitional Wrapper for entire site.
contains:
   Routing for:
      Landing
      Features
      Sample Site
      Protected - Entry way to user apps
   
    Also contains theming for @MUI

note: upon load, App determines if logged in 
     -- if yes
      1) dispatch user Id to Redux store
      2) redirect to Main Page
     -- if no - show Landing Page
*/




import React, {  Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 

import { Route, Routes , Navigate} from 'react-router-dom'


// import FirebaseAuthService from './firebase/FirebaseAuthService.js';
// import { selectLoadingStatus, updateUid, selectUid } from './redux/statusRedux/statusSlice.jsx';

import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline'

import theme from '../styles/Theme'

// import Loading from '../common_components/Loading';
import ZobadaLanding from '../pages/public/ZobadaLanding'
import Contact from '../pages/public/ContactForm'
 import Main from '../pages/Main';
 import Home from '../pages/Home';
 
import { selectLogin } from './redux/loginSlice';


 






const App = () => {


  let status = useSelector(selectLogin)
  let login = status.login
  const [loginState, setLoginState] = useState('login')
  console.log('[ App ] loginState ', loginState);



  useEffect(()=>{

    setLoginState(login)

  }, [login])

  console.log('[ App ] loginState ', loginState);
     

 

    // ---return
    return (
      <CssBaseline>
        <ThemeProvider theme = {theme} >

  

        <Routes>
        {loginState && <>
              <Route path='/main/home' element={<Home />} />
              <Route path='/main/:page' element={<Main />} />
              <Route path='/main' element={<Main />} />
              <Route path='/home' element={<Home />} />

 
        </>}    

      {/* 
            


             */}

            <Route path='/' element={<ZobadaLanding />} />
            <Route path='/contact' element={<Contact />} />

            {/* <Route path='/*' element={<NotFound />} /> */}



          </Routes>
           


              
        </ThemeProvider>
      </CssBaseline>
        
    )
  }


export default App;