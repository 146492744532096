import React from 'react';
 
import { chitBurgandy, chitBurgandyDull, chitOrange, chitYellowMedium, lightGrey } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function MentorList(props) {

  const [open, setOpen] = React.useState(false);



  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
  
<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
   
      <DialogContent>
        <HeaderWrapper> 
          
         <div>Jay Dorne</div>
         <div> retired Creative Director - Omnicron</div>
         </HeaderWrapper>
         <DialogWrapper>
         <div> Omnicron is the 2nd largest full service agency in the world.
          I was in charge of  creating the entire media program for xxx ,and yyy.  
         </div>
         <div> Can take on 2 interns</div>
         <div> - standard package $450 - includes portfolio analysis <br/>
              - platinum package includes personal tour of London Headquarters - $20K
          </div>
          </DialogWrapper>
      </DialogContent>
      <DialogActions>


      <StyledButton
          form="submit-form"
          variant="contained"
          color="primary"

          onClick={handleClose}
        >
          Apply
        </StyledButton>

        <StyledButton
          form="submit-form"
          variant="contained"
          color="primary"

          onClick={handleClose}
        >
          Cancel
        </StyledButton>

      </DialogActions>
    </Dialog>


    <Wrapper>
<Header> Coaches and Mentors for this project</Header>
      <div> Coaches</div>
      <CoachWrapper>
        <div>Steve Roe</div>
        <div>owner Roe and Associates</div>
        <Rating>avg rating: 89 of 100</Rating>
      </CoachWrapper>

      <CoachWrapper>
        <div>Mary Cole</div>
        <div>account manager - VPU Media</div>
        <Rating>avg rating: 92 of 100</Rating>
      </CoachWrapper>


      <div > Mentors</div>
      <MentorWrapper  onClick = {() => setOpen(true)}>
        <div>Jay Dorne</div>
        <div>Retired creative director - Omnicon</div>
        
        <div>packages starting from $450</div>
        <div>avg rating: 100 of 100</div>
      </MentorWrapper>
     
    </Wrapper>
    </Container>
  );
}

// ---------------------------

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  width: '96%',
  flexWrap: 'wrap',
 
   

  fontSize: '.85rem',

 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
 
  width: '100%',
  flexWrap: 'wrap',
 
   

  fontSize: '.85rem',

 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})
const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  textTransform: 'none',
  '&:hover' :{
    backgroundColor: lightGrey
  }
})


const MentorWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: 'white',
  width: '80%',
  flexWrap: 'wrap',
backgroundColor: 'red',
   cursor: 'pointer' ,
  padding: '1rem',
  margin: '.5rem 1rem .2rem 1rem',

  fontSize: '.85rem',

 


  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',

  },
})


const CoachWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
 
  width: '80%',
  flexWrap: 'wrap',
backgroundColor: 'white',
padding: '1rem',
margin: '.5rem 1rem .2rem 1rem',
  fontSize: '.85rem',


  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
    padding: '.5rem',
    fontSize: '.75rem',
  },
})

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  color: chitBurgandy,
  width: '100%',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const Rating = styled('div')({
 color: chitBurgandy,
})

const HeaderWrapper = styled(Paper)({
  color: chitBurgandy,
  padding: '.5rem',
 })

 const DialogWrapper = styled('div')({
  width: '90%',
  margin: '.5rem',
  fontSize: '.85rem',

  '& div' :{
    marginBottom: '.5rem',
  }
 })

