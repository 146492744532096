import React from 'react';
 
import { chitBurgandy, chitOrange, chitYellowMedium } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function DetailsSecondaryMarket(props) {
  return (
    <Wrapper>
      <Header> Project: <div>Secondary Research</div></Header>

      <Goal> The goal of this project is to find an opportunity for a new product or new product feature. <br/>
        Analyze a product category you use and are familiar with such as soft drinks or tennis racquets. 
</Goal>

<Title>  To Do:   </Title>
      <Description> 
             
        
        <div>
        Evaluate  the category competitors<br/>
        Take the # 3 competitor <br/>
        Create a SWOT analysis.<br/>
        Make recommendations on where to spend corporate dollars for the #3 company to move to # 2 in 5 years or less. 
        </div>
      </Description>

      <Title> Output:   </Title>

      <Description> <div> 
        Presentation of findings. <br/>
        Written - 3 page max <br/>
        PowerPoint <br/>
        bonus for Video of you presenting
        </div>
        </Description>

        <Title> Prerequisites: </Title>
        <Description>  <div> 
        presentation software<br/>
         
        </div>
        </Description>
  
        <Title> Materials suggestion: </Title>
        <Description>  <div> 
          "Competitive Advantage" by Porter<br/>

         
        </div>
        </Description>

    </Wrapper>
  );
}

// ---------------------------

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '6px 6px',

  width: '75%',
  padding: '1%',
  fontSize: '.85rem',
 
  [theme.breakpoints.down('md')] : {
                              
    fontSize: '.8rem',

    width: '100%',
 
 }
})

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  width: '98%',
   
  fontSize: '1rem',

  '& div': {
    marginLeft: '2rem',
    color: 'black',
    fontSize: '.9rem',

     
  [theme.breakpoints.down('md')] : {
                              
    fontSize: '.8rem',

 
 }
  },

   
  [theme.breakpoints.down('md')] : {
                              
    fontSize: '.9rem',

 
 }
})

  const Goal = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: chitBurgandy,
  
    width: '98%',
     
  
      marginLeft: '2rem',
      color: 'black',
      fontSize: '.9rem',
 
 
      [theme.breakpoints.down('md')] : {
                              
        fontSize: '.8rem',
    
     
     }
})

const Description = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  width: '98%',
   marginTop: '.3rem',
  fontSize: '.8rem',

  '& div': {
    marginLeft: '2rem',
    color: 'black',
    fontSize: '.9rem',

    [theme.breakpoints.down('md')] : {
                              
      fontSize: '.8rem',
  
   
   }
  },

  
})

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  // width: '25%',
   
  fontSize: '.9rem',

 
})

