import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, chitBlueDull, chitBurgandy } from '../../styles/colors';


// --- MUI
 
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



 

export default function AdminHome(props) {
  return (
    <Container>
      <Title>Admin Home Features</Title>

      <div>
        <div> Site management</div>
        <div> Customer service</div>
        <div> Projects management</div>
        <div> Mentors and coaches management</div>
        <div> Legal </div>
      </div>

    </Container>
  );
}
 


const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '100%',
  height: '95%',
  paddingTop: '5%',
  color: 'black',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const Title= styled('div')({
 color: chitBurgandy,
 margin: '2rem'

})

