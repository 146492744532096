import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, chitBurgandy, mediumMediumGrey } from '../../styles/colors';

import MentorHomeHeader from './components/MentorHomeHeader';

// --- MUI

import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



export default function MentorHome(props) {
  return (
    <Container>
      <MentorHomeHeader />

      <ProjectWrapper>
        <ProjectHeader>
      
          <ProjectTitle>   Explore projects to mentor   </ProjectTitle>
 
        </ProjectHeader>
        <DisciplineWrapper>


          <Discipline> Finance</Discipline>
          <Discipline> Sales</Discipline>
          <Discipline > Marketing</Discipline>

           
          <Discipline> Discipline</Discipline>
          <Discipline> Discipline</Discipline>
          <Discipline> etc</Discipline>
        </DisciplineWrapper>
      </ProjectWrapper>

      <CoachMentorWrapper>
        
         Coaches vs Mentors

        <Difference>  What is the difference ? </Difference>
      </CoachMentorWrapper>
      <Wrapper>

        <LeftWrapper>


          <div>
            <Title>Coaches</Title>
            <Section>
              <ul>
                <li>  project helpers only</li>
                <li> get fix pay incentive </li>
                <li>  packages for a price <br />
                  - fixed # group zoom calls<br />
                  - fixed # of written chats
                </li>
                <li>  additional charges for more <br />
                </li>

              </ul>


            </Section>
          </div>



        </LeftWrapper>

        <RightWrapper>
        
            <Title>Mentors</Title>
            <Section>
              <ul>
                <li>  project helpers +  </li>
                <li>  charges based on who they are </li>
                <li>  offer custom packages  <br />
                  ie. one-on one calls or meetings <br />
                  or help outside of project, etc

                </li>
              </ul>


            </Section>
       

        </RightWrapper>
      </Wrapper>
    </Container>
  );
}


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
  color: 'black',
  
  //  overflowY: 'auto',
  //  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {

    alignItems: 'center',
    width: '100%',
    padding: '0',


  }

})
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
 marginTop: '1px solid grey',
  width: '60%',

 
backgroundColor: 'white',
   paddingTop: '2rem',
 
  fontSize: '.85rem',
 
 


  [theme.breakpoints.down('md')]: {
    width: '100%',
 
  },
})
const LeftWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

  width: '50%',
 
  fontSize: '.9rem',

 
})


const RightWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

  width: '50%',
   
  fontSize: '.9rem',

 
})

const Section = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  color: 'black',
  width: '100%',

 
   
 
  fontSize: '.85rem',
 
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const Title = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: chitBurgandy,

 
   
  fontSize: '.9rem',

 
})

const ProjectHeader= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '60%',
  margin: '0 4px',
  

  color: chitBurgandy,
  

  [theme.breakpoints.down('sm')] : {
                            
 }
})


const DisciplineWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
 
  paddingBottom: '4px',
  color: 'black',
  

  [theme.breakpoints.down('sm')] : {
                            
 }
})

const Discipline= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  padding: '2px 1rem',
  margin: '0 1rem 0 0 ',
  borderRadius: '8px',
   
  color: 'black',
  backgroundColor: lightGrey,
  

  [theme.breakpoints.down('md')] : {
    padding: '2px .2rem',   
    fontSize: '.75rem'                
 }
})

const ProjectWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
  height: '7rem',
  color: 'black',
  marginBottom: '1rem',
  backgroundColor: 'white',
  borderTop: '1px solid orange',
 borderBottom: '1px solid grey',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const ProjectTitle= styled('div')({
  marginBottom: '.75rem',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {


 }
})

const CoachMentorWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  
  width: '100%',
 
  color: chitBurgandy,
 
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }
})

const Difference= styled('div')({
 
  
 
  color: darkGrey,
  fontSize: '.85rem',
 
  [theme.breakpoints.down('sm')] : {
                              
 
   
 
 }
})
