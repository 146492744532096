import React from 'react';
 
import { chitBurgandy, chitOrange, chitYellowMedium } from '../../../styles/colors';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//  ==================================


export default function Messages(props) {
  return (
    <Wrapper>
      <ul>  
      <Title> Possibilities</Title>
      <div> 
        <StyledLi> Stackoverflow type discussions : <br/>
              Q & A among all users who took the projector
         </StyledLi>
        <StyledLi>Instant message type discussions <br/>
              between coaches (or mentors) and participants
        </StyledLi>
        

      </div>

      </ul>

 

    </Wrapper>
  );
}

// ---------------------------

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '6px 6px',

  flexWrap: 'wrap',
backgroundColor: 'white',
   
  width: '75%',
  fontSize: '.85rem',
  height: '14rem',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const Title = styled('div')({
  marginBottom: '2rem',
  fontSize: '1.1rem',
  color: chitBurgandy,
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const StyledLi = styled('li')({
  marginBottom: '1rem',
  fontSize: '.9rem',
 
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})
