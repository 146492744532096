import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey } from '../../styles/colors';

import ProjectHeader from './components/ProjectHeader';
import DetailsPrimaryMarket from './components/DetailsPirmaryMarket'
import MentorList from './components/MentorList'
// --- MUI
 
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


export default function ProjectPage(props) {
  return (
    <Container>
      <ProjectHeader/>

      <DetailWrapper> 
        <DetailLeft>
          <DetailsPrimaryMarket/>
        </DetailLeft>

        <DetailRight>
          <MentorList/>
        </DetailRight>

      </DetailWrapper> 

    </Container>
  );
}

const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '100%',
  height: '100%',


//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const DetailWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '100%',
  borderTop: '1px solid grey',

 overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const DetailLeft= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  borderRight: '1px solid orange',
  width: '50%',
  height: '100%',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const DetailRight= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
 
  width: '50%',
  height: '100%',

//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('sm')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})