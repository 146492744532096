import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import { chitOrange, chitBurgandyDull, lightGrey, shadowBlue, chitOrangeLight, bodyBlue, darkGrey, mediumGrey, chitBurgandy, mediumMediumGrey, chitDarkLavendar, backgroundBlue } from '../../../styles/colors';

// --- MUI
 
import Button from '@mui/material/Button'


import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// ========================================
export default function Analysis(props) {


  return (
    <Container>
      <Wrapper>
        <MainHeadline> LenCo.edu</MainHeadline>

        <DocumentWrapper>


          <SubHeader> 
      
            The following is my analysis and thought process - Brad Brous
        
          </SubHeader>
<StyledP/><StyledP/>

          <Section>
            The website described here will be referred to as :  <BoldEm>Lenco  </BoldEm>
          </Section>

          <Section>
            <Note>
              <NoteLeft>note: </NoteLeft>

              <NoteRight>
                This document is based on a vision created by Len Schutzman.
                It may or may not be what he described to me, but I assign
                ownership of all thoughts and ideas shown here to him… Brad Brous


              </NoteRight>

            </Note>
          </Section>

          <Headline> Opening Thoughts</Headline>
          <Title> Concept Description </Title>

          <Section>
            In essence Lenco’s benefit to users is  :  Proof of Ability or Capability

            <StyledP />
            Lenco will be a virtual internship with 3 key pillars:
            <ul>

              <StyledLi> student or worker</StyledLi>
              <StyledLi> projects </StyledLi>
              <StyledLi> mentors (or coaches)  </StyledLi>
            </ul>



            Lenco will be a single source portal to all the stakeholders (users, mentors, and admin).
            <StyledP />
            <Note>
              <NoteLeft>note: </NoteLeft>

              <NoteRight>
                An additional stakeholder might be Universities… but they are not considered here.


              </NoteRight>

            </Note>
            <StyledP />
            Users can view and sign up for projects.
            Mentors  can sign up, schedule, and communicate with users.


          </Section>

          {/* ---------------------------------------------- */}

          <Headline> Competition</Headline>
          <StyledP />
            
              <Section>
              <Note>
              <NoteLeft>note: </NoteLeft>

              <NoteRight>
              colleges and universities may be viewed as competition or as partners for Lenco.


              </NoteRight>

            </Note>
                            
            </Section>
            <StyledP />
          <TitleEm> Internships: </TitleEm>

          <Section>
            The first competition that comes to mind is normal internships.  There are thousands (millions) of internships advertised by corporations and small businesses.
            <StyledP />
            Thousands apply to a company like Apple for a single internship … and only 1 or 2 will be chosen.
            <StyledP />
            One Lenco advantage is that it can allow hundreds and thousands of users to try their hand at a single virtual internship in their chosen discipline.
            <StyledP />
            Also, business internships offered currently could  be menial in actuality… or produce outputs that are proprietary to the company.  In such cases, an intern’s work can not be shared with other employers.  So the only thing that proves actual capability for the intern is a line item on her resume… and maybe a recommendation.
            <StyledP />
            Lenco can allow the user to show an actual output for the project.


          </Section>
  {/* ------------------------------------------ */}
          <StyledP />
          <TitleEm> EdTech: </TitleEm>

          <Section>
            Edtech, for this analysis, can be split between those sites that offer K - 12 education (ie: Khan Academy), and those that offer adult education (ie. Udemy).
            <StyledP />
            Here only adult targeted edtech sites will be looked at.
            <StyledP />
            The main sites are:
            <List>
              <ListLeft> - edX: </ListLeft>
              <ListRight> 100 mil users    </ListRight>
            </List>

            <List>
              <ListLeft> - CourseRa: </ListLeft>
              <ListRight> &nbsp; 92 mil users    </ListRight>
            </List>

            <List>
              <ListLeft> - Udemy: </ListLeft>
              <ListRight> &nbsp; 52 mil users    </ListRight>
            </List>

            <List>
              <ListLeft> - LinkedIn: </ListLeft>
              <ListRight> &nbsp; 27 mil users    </ListRight>
            </List>

            <List>
              <ListLeft> - Udacity: </ListLeft>
              <ListRight> &nbsp; &nbsp1.6 mil users    </ListRight>
            </List>


          </Section>

    {/*  ---------------------------------------- */}
          <TitleEm> Key Distinction:</TitleEm>
          <Section>



            All current edtech sites offer project work.  Some like Udacity have “mentors”.  They all offer certifications.
            <StyledP />
            But they are all focused on teaching (not doing).  In essence they all sell video lessons.  Competitor projects take the form of a series of videos with step by step descriptions on how the instructor chose to approach and solve the challenge.
            <StyledP />
            Two problems with this approach:
            <IndentedSection>
              First, the certifications given are proof of course completion or quiz completion for individual lessons… not competence in applying what is learned.

              2nd and most significantly, the lessons are constantly out of date and require replacement … especially in software and tech.  A very costly endeavor.
            </IndentedSection>
            <StyledP />
            So the output benefit for users   is “certification”.  Certification does not show an ability for the user to prove their ability to solve problems, or show creativeness, or display uniqueness in approaches to solving a problems.
            <StyledP />
          </Section>
   {/* ---------------------------------------- */}

          <Title> Lenco Website</Title>
          <StyledP />
          <Title> Goals</Title>
          <StyledP />

          <Section>

            The main goals of the Lenco website is:
            <IndentedSection>
              <Burgandy>
                1. To provide the user with demonstrable proof of their ability and
                capabilities that they can show someone else.
              </Burgandy>
              <StyledP />
              <Burgandy>
                2. To be able to attract and retain quality mentors.
              </Burgandy>
            </IndentedSection>
            <StyledP />
            <SectionRow>
              <Blue> Demonstrable proof… </Blue> the key word is “demonstrable”.
            </SectionRow>
            <StyledP />
            Certification attained using Lenco edtech competitors is not indicative of a user’s competence.
            <StyledP />

            With Lenco,  a student solves the project themselves without the benefit of seeing the solution beforehand… and they can show the actual solution of the project itself to employers (along with the evaluation).
            <StyledP />
            <SectionRow>
              <Blue> Attract Quality Mentors… </Blue>the key words are “quality” and “attract”.

            </SectionRow>
            <StyledP />

            Mentors need a reason to spend their time helping Lenco users… most of whom they will never meet face to face.
            <StyledP />

            Quality in mentors is subjective.  Mentors need to be screened and filtered, by some mechanism
            <StyledP />


          </Section>

        {/* ---------------------------------------- */}

          <Title> Business Model: </Title>

          <Section>
            <BoldEm> Competitors: </BoldEm>
            <StyledP />

            Lenco competitors are  focused on teaching.    So users pay to learn something.  All of them provide certification for additional cost.
            <StyledP />

            One main “con” for all these edtech sites is - to gain the user’s desired output (certification) in a  course requires the user to watch every single video in a course whether they are already familiar with the subject matter or not.  Some of these courses can be 25 hours or more.  So watching and learning just the parts of a course the user doesn’t already know will not result in certification.
            <StyledP />
            <Blue> CourseRA and  EdX :  </Blue>

            <StyledP />

            The EdX and CourseRA platforms are almost identical in strategy, price and implementation.  Choose one over the other based on what you want to learn.  From a competitive standpoint with respect to Lenco they are interchangeable.
            <StyledP />

            CourseRa offers courses from well known universities.  They offer degrees which can cost between $9,000 and $40,000. Or, users can subscribe to courses for $40 a month or take guided projects for $10,
            <StyledP />
            <Blue> Udemy :  </Blue>

            <StyledP />

            Udemy offers courses submitted by anyone who claims to be an expert.  Udemy uses a user ranking for each course to filter the courses and instructors for quality.  Courses range from free to $250.  But pricing sales are very frequent and bring the cost of a course down to around $12.

            Users purchase the course.  So it is theirs forever.  Authors of the course may or may not update their courses regularly.  When they do, users who purchased an older version of the course will get upgraded to the newer version for no additional cost.

            <StyledP />

            <Blue> LinkedIn :  </Blue>

            <StyledP />

            LinkedIn School was previously Lynda.com.  LinkedIn’s model is the same as Udemy with one difference.  The user pays a $30 monthly subscription.
            <StyledP />

            While subscribed, the user can attend any course offered.  But once the user unsubscribes, no access to the courses are available.
            <StyledP />
            <Blue> Udacity :  </Blue>

            <StyledP />

            Udacity sells “nanodegrees” for a discipline.  Subscriptions are around $400 with the total cost of nanodegree costing anywhere from $700 to $2,500.  They have mentoring.
            <StyledP />

            Udacity has a small fraction of total users as compared to the other competitors.


          </Section>

          {/* --------------------------------- */}

          <Title> Lenco Positioning: </Title>

          <Section>
            <Italic> Positioning relative to competition </Italic>
            <StyledP />

            Lenco should position itself as a “project provider”…not a “MOOC”.
            ( MOOC - massive open online courses )
            <StyledP />

            MOOC’s teach using video lessons.  Users learn by watching passively or by duplicating what they see.
            <StyledP />

            Lenco does not teach but rather makes users learn.  This requires users to find the knowledge gaps they may require for a project on their own.   Lenco’s value added is mentor access and evaluation of the user’s solution to the project challenge.
            <StyledP />


            Since Lenco’s value added is different than a MOOC,  it  means that <Burgandy> Lenco’s pricing model must also be different.</Burgandy>

          </Section>

          {/* -------------------------------- */}


          <Section>
            <Italic>
              Lenco Features and Pricing
            </Italic>
            <StyledP />
            Lenco lends itself to a number of possible opportunities to monetize itself that are very different from MOOCs.
            <StyledP />
            Since there are no courses involved with Lenco, the actual thing that a user is paying for is the evaluation of their solution of the project + mentoring time.
            <StyledP />
            Mentoring
            <StyledP />
            Lenco’s main cost from a project is mentoring.
            <StyledP />
            For the purposes of this paper -  mentoring includes of the user’s evaluation of the user’s output.
            <StyledP />
            Since some students require little or no mentoring, and other will require lot’s of mentoring… Lenco’s pricing model must take this large discrepancy into  account.
            <StyledP />
            Mentors must be incentivized in order to keep mentoring.  So mentors must be compensated… and they must be compensated based on the user’s demands on their time.
            <StyledP />
            <Blue>
              Thus base pricing model should be something like:
              <StyledP />
              <IndentedSection> <Burgandy>
                $35 to take a project and get evaluated and certified,
                <StyledP />
                +     additional charges for a menu of mentoring options.

              </Burgandy></IndentedSection>
              <StyledP />
              Mentoring prices could be an add on … something like:
              <StyledP />
              <IndentedSection> <Burgandy>
                $ 55 for 3 group mentoring sessions on Zoom,
                <StyledP />
                $ 55 for one 15 minute private session on Zoom,

              </Burgandy> </IndentedSection>
              <StyledP />

            </Blue>

            <StyledP />

            However, some mentors are better than others at mentoring.  Some mentors are worth more than others because of who they are, what their job title is,  or where they work.
            <StyledP />

            So mentors must be somehow ranked and filtered and the prices should reflect their market value.
            <StyledP />
            <SectionRow>
              This could lead to a &nbsp; <Burgandy> variable pricing model  </Burgandy ></SectionRow>where someone like Warren Buffet deciding to mentor a user… he could charge $100,000 for a 20 minute private session.
            <StyledP />

            Mentors who are not known can be graded and ranked by users who undertake a project.  This could lead to a mentoring ladder where highly ranked, in demand mentors can charge more.
            <StyledP />

            Thus Lenco’s primary pricing / profit model should be to extract a commission on what the mentor is charging.
            <StyledP />
            The mentoring ladder approach further incentivizes mentors in that they can use their Lenco mentoring success for their own career advancement.
            <StyledP />

          </Section>


     {/* ---------------------- */}

          <Section>
            <Italic>
              Mentoring and Coaching
            </Italic>
            <StyledP />

            It became clear that there is a huge differences between mentors - not only who they are, but what they are able to offer or are willing to offer.
            <StyledP />

            So, in the Business section I created 2 classes of mentors.
            <StyledP />

            &nbsp; &nbsp; &nbsp; - Coaches
            <StyledP />
            &nbsp; &nbsp; &nbsp;   - Mentors
            <StyledP />

            Coaches will only perform the tasks Lenco requires with respect to the student.  That is answering and guiding the student to a solution for the project.  The students should be able to choose the coach they want based on rankings of the coach provided by previous project participants .
            <StyledP />

            Mentors can offer other services - such as job hunting advice or consulting packages to the student like resume review.   Thus they can charge whatever the market will bear. Mentors should also be able to choose users they wish to mentor… thus filtering the users in some way.

            <StyledP />

          </Section>

          <StyledP />
            
     {/* ---------------------- */}

     <Section>
            <Italic>
            Projects, Features and Users
            </Italic>
            <StyledP />

            Projects themselves can vary in degree of difficulty, number and type prerequisites and the specific mentors that have the capabilities of helping the user with a project.
<StyledP />
            
So for entry level projects, the base project pricing model works.  But it  fails with advanced projects requiring high level mentors.
<StyledP />
            
Also, users should be allowed to undertake a given project multiple times so they can fail their way to success… learning something from each failure.
<StyledP />
            
Projects should also might have a time component… because deadlines are part of the business world and because mentors will not wish to have an indeterminate commitment of their time and participation.  
<StyledP />
            
The problem with project deadlines is that users may be able to commit to X time for a project, but will require Y days or weeks to commit that time. (unresolved here)
<StyledP />
            
Potential project feature:  When time is completed … the project is evaluated as it stands.
<StyledP />
            
Thus Lenco’s pricing model should take failure into account and encourage users to undertake projects again by providing a discount.
<StyledP />
            

        </Section>
        <StyledP />
            

     {/* ---------------------- */}

     <Section>
            <Italic>
            Website Project Features
            </Italic>
            <StyledP />

            <Italic>
            Prerequisites
            </Italic>
            <StyledP />

            Each project should provide the user with a list of what the user should know to successfully complete the project… 
            <StyledP />

The user can then decide for themselves what they need to know  and choose where to obtain the knowledge... like from a book or Udemy or YouTube, etc.

<StyledP />
 

            <Italic>
            Outputs
            </Italic>
            <StyledP />

            The projects should accept various outputs… written documents, PowerPoints, podcasts, or video.  
            <StyledP />

The user’s output should be accompanied by the evaluator’s written evaluation.
<StyledP />

Grades can be assigned or pass fail (TBD).  But the user should be able to show the output to others with or without the evaluations shown.
<StyledP />

Project feature - another revenue source:   Businesses should be able to view the outputs… 
- for free if the user gives their permission 
- for a fee to any business wishing to view project outputs.  
<StyledP />


Project feature - Business sponsorship of projects
<StyledP />

A business should be able to sponsor a project.  And they should be allowed to provide their own mentors.  It may make sense to allow this for no cost to the business… but with any fees collected, including mentor fees paid to Lenco.
<StyledP />

Businesses who sponsor projects get the benefit of first look at the users who completed the project.  
<StyledP />

By providing mentors for their sponsored projects, businesses get to assess their own employees mentoring capabilities.

<StyledP />

Businesses have 2 needs:
   - finding prospects for positions they may want to fill
   - getting outside approaches to problems they may be trying to solve

   <StyledP />

With respect to finding prospects, this was discussed above.

<StyledP />

With respect to resolve problems - my solution was to give a business the ability to create a closed “private” sponsorship.  The users would need to apply (just like a real internships) … but the solutions and problems remain the property of the sponsor.  This is counter to the basic premise of Lenco… so Lenco can charge for the feature.




</Section>

{/* --------------------------- */}


<StyledP />

<Title> Lenco Bonus</Title>
<StyledP />

<Section>
Lenco could be a great platform to apply gamification in the form of contests and ladders.
<StyledP />

Ladders can be created in a number of ways or combinations.  
<StyledP /><IndentedSection> 
1 -  output assessments can be scored.  A users place on 
    the ladder would be determined by the assessor’s score.
    <StyledP />
2 -  outputs can be shared with all users who completed the 
    project… and they can score them… the sum of all scores
    providing the ranking.
    </IndentedSection>
    <StyledP />
Competitions can be run by business sponsors can rank the outputs and provide an  incentive (like a job or product) to the top participants.

</Section>

{/* --------------------------- */}
<StyledP />

<Title>Website Development- Specs / Costs:  </Title>
<StyledP />

<Section>
There is no way to know the true scope (and therefore cost) of Lenco until a specification, wireframe or an alpha version of the site is created.  
<StyledP />

This is because a seemingly small feature can require a great deal of coding time.  So, to price it out - all features for the minimum viable product (MVP) must be decided upon.
<StyledP />

That said - Lenco is a significant undertaking.  Each “portal”  (user, mentor, etc) is a complete website.   
<StyledP />

The way to approach it is to create an “alpha” version of site…And a business plan.   
<StyledP />

(note: an alpha version is a website is one that looks and feels like the end product so 3rd parties can experience it.  The data is fabricated and there is minimal actual coding.)
<StyledP />

<Burgandy> 
This should take around 2 -3 months and is a 2 person job.
<StyledP />

<IndentedSection>
- a business person to decide on features, pricing model, etc
<StyledP />
- a web developer.
</IndentedSection>
</Burgandy>


<StyledP /><StyledP />
<End>-------- End ------- </End>



</Section>




      </DocumentWrapper>
          
      </Wrapper>
    </Container>
  );
}

// ------------------------------------------
const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
 
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
 
 overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const Wrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
 
  width: '80%',
  height: '100%',
  backgroundColor: 'white',

 
 overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const DocumentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
 
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
 
 padding: '1rem 2rem',
 
  [theme.breakpoints.down('md')] : {
                              

 }

})

const MainHeadline= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: chitOrange,
  fontSize: '2rem',
  fontWeight: 'bold',
  width: '100%',
 
 
  [theme.breakpoints.down('md')] : {
   fontSize: '1.5rem',                 
 
 }

})

const Section= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
 
  width: '100%',
 
 
 
 padding: '.25rem 2rem',
 
  [theme.breakpoints.down('md')] : {
                              

 }

})

const IndentedSection= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
 
  marginLeft: '2rem',
  [theme.breakpoints.down('md')] : {
                              

 }

})

const SectionRow= styled('div')({
  display: 'flex',
  flexDirection: 'row',
 
 
 
  [theme.breakpoints.down('md')] : {
                              

 }

})



// ============== Emphasis ============================

const BoldEm= styled('span')({
  fontWeight: 'bold',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const Italic= styled('span')({
fontStyle : 'italic',
fontWeight: 'bold',
  [theme.breakpoints.down('md')] : {
                              
 }

})



const Burgandy= styled('span')({
  color: chitBurgandy

})

const Blue= styled('span')({
  color: backgroundBlue

})

const Red= styled('span')({
  color: 'red'

})


 // ============== End Emphasis ========================

 
const Note= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '.8rem',
  width: '100%',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const NoteLeft= styled('block')({
  display: 'flex',
  flexDirection: 'row',
 
  width: '3rem',
  margin : '0 1.2rem',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const NoteRight= styled('block')({
  display: 'flex',
  flexDirection: 'row',
 
  width: '70%',
  marginRight: '1rem',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const List= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '.9rem',
  width: '100%',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const ListLeft= styled('block')({
  display: 'flex',
  flexDirection: 'row',
 
  width: '8rem',
  margin : '0 1.2rem',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

const ListRight= styled('block')({
  display: 'flex',
  flexDirection: 'row',
 
  width: '70%',
  marginRight: '1rem',
 
  [theme.breakpoints.down('md')] : {
                              
 }

})

// ----------------------------------------

const Headline= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'black',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  // width: '100%',
  padding: '.5rem 2rem',
 
  [theme.breakpoints.down('md')] : {
   fontSize: '1.5rem',                 
 
 }

})

const Title= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'black',
  fontSize: '1rem',
  fontWeight: 'bold',
  // width: '100%',
  padding: '.5rem 2rem',
 
  [theme.breakpoints.down('md')] : {
   fontSize: '1.5rem',                 
 
 }

})

const TitleEm= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'black',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  fontStyle: 'italic',
  // width: '100%',
  padding: '.5rem 2rem',
 
  [theme.breakpoints.down('md')] : {
   fontSize: '1.5rem',                 
 
 }

})

const StyledLi= styled('li')({
 listStyleType: 'circle',
 listStylePosition: 'inside',
 
  [theme.breakpoints.down('md')] : {
                
 
 }

})

const StyledP= styled('div')({
 
  margin: '.3rem',
   [theme.breakpoints.down('md')] : {
                 
  
  }
 
 })


 const SubHeader= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: chitBurgandy,
  fontSize: '.9rem',
 width: '100%',
  [theme.breakpoints.down('md')] : {
 
 }

})

const End= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
 
})