import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import {  lightGrey, mediumGrey } from '../../styles/colors';

import UserProjectPageHeader from './components/UserProjectPageHeader'
import Note from './components/Note';
import Details from './components/DetailsSecondaryMarket';
import Attachments from './components/Attachments';
import Messages from './components/Messages';
import Mentor from './components/Mentor';

import { updateUserProjectTab, selectStatus } from '../../app/redux/statusSlice';

// --- MUI
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




// =============================

export default function UserProjectPage(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus)

  let tabState = status.userProjectTab
  console.log('[ USerProjectPgae ] tabState ', tabState);

  const handleChange =(id)=>{

    console.log('[ UserProjectPage ] handleChange clicked ', id);
    dispatch(updateUserProjectTab(id))
  }

  return (
    <Container>
      <UserProjectPageHeader/>
<Wrapper> 
      <TabWrapper>

        {tabState === 'details' && 
        <TabActive >Details</TabActive>
        }
        {tabState !== 'details' && 
        <Tab onClick = {()=>handleChange('details')}>Details</Tab>
        }

{tabState === 'notes' && 
        <TabActive >Notes</TabActive>
        }
        {tabState !== 'notes' && 
        <Tab onClick = {()=>handleChange('notes')}>Notes</Tab>
        }

{tabState === 'attachments' && 
        <TabActive >attachments</TabActive>
        }
        {tabState !== 'attachments' && 
        <Tab onClick = {()=>handleChange('attachments')}>Attachments</Tab>
        }

{tabState === 'messages' && 
        <TabActive >Messages</TabActive>
        }
        {tabState !== 'messages' && 
        <Tab onClick = {()=>handleChange('messages')}>Messages</Tab>
        }

        {tabState === 'mentor' && 
                <TabActive >Mentor</TabActive>
                }
                {tabState !== 'mentor' && 
                <Tab onClick = {()=>handleChange('mentor')}>Mentor</Tab>
                }

 

      </TabWrapper>
      {tabState === 'notes' && 
      <>
        <Note/> 
      </>
              }

{tabState === 'details' && 
      <>
        <Details/> 
      </>
              }

{tabState === 'attachments' && 
      <>
        <Attachments/> 
      </>
              }
    {tabState === 'messages' && 
      <>
        <Messages/> 
      </>
              }
      {tabState === 'mentor' && 
      <>
        <Mentor/> 
      </>
              }
              </Wrapper>
    </Container>
  );
}

const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'black' ,
  width: '100%',
  height: '100%',
  color: 'black',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})

const Wrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'white' ,
  width: '80%',
  height: '100%',
  color: 'black',
//  overflowY: 'auto',
//  overflowX: 'hidden',
  [theme.breakpoints.down('md')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }

})





const TabWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '1px solid grey',
 

   
  width: '100%',
  marginBottom: '4px ',
  padding: '8px 0',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const TabActive = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
 
  width: '7rem',
  fontSize: '.8rem',
  backgroundColor: lightGrey,
  border: '1px solid grey',
  marginRight: '1px',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const Tab = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid red',
  width: '7rem',
  fontSize: '.8rem',
  backgroundColor: 'red',
  color: 'white',
  marginRight: '1px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: mediumGrey,
    border: '1px solid grey',
    color: 'white'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


 
 