import React, {useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom'
 

import {chitBurgandyDull, lightGrey, mediumLightGrey} from '../../styles/colors'

// --- React-hook-form imports ---------
import emailjs from 'emailjs-com'
 
 
 
 

import Button from '@mui/material/Button'; 
import { styled, createTheme  } from "@mui/material/styles"

 
const theme = createTheme(); // allows use of mui theme in styled component

 
//---------Main function -----------------------------------

function ContactForm(props) {
  let navigate = useNavigate()
  const form = useRef()

 
  const handleClick = ()=>{

    navigate(`/contact`)
  }


  // let login = sessionStorage.getItem("login")
 

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_dewj4fo','template_eg1nlid', form.current, 'yM0K0Q1prhrj1XthY')
    .then((result) => {
        // console.log(result.text);
        alert('your message has been sent')
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
};
  



 
  return (
    <BodyWrapper>

      
      <Wrapper>
        <Header> Contact me</Header>
       <Links onClick = {()=>handleClick()}> back to home  </Links>
 
 
 <FormWrapper ref= {form} onSubmit={sendEmail}>
 
 
 

 <StyledLabel>Name</StyledLabel>
 <StyledLabel>First name</StyledLabel>
      <StyledInput type="text" name="firstName" />
      <StyledLabel>Last Name</StyledLabel>
      <StyledInput type="text" name="lastName" />
      <StyledLabel>Email</StyledLabel>
      <StyledInput type="email" name="email" />
      <StyledLabel>Subject</StyledLabel>
      <StyledInput type="text" name="subject" />
      <StyledLabel>Message</StyledLabel>
      <StyledTextField name="message" />
 


 
 

 

      <StyledButton type="submit" value="Send" >Send  </StyledButton>


        </FormWrapper>
 

     
      </Wrapper>


      <NameWrapper>
        Zobada - a personal site for Brad Brous

      </NameWrapper>



    </BodyWrapper>
  );
}

export default ContactForm

// ========================================
const BodyWrapper= styled('div')({
  display: 'block',
  color: 'white',
  backgroundColor: 'black' ,
  height: '100vh',
 
  fontFamily: 'Lato, sans-serif',
  overflow: 'hidden',
 

  [theme.breakpoints.down('xs')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Wrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '100%',


  [theme.breakpoints.down('sm')] : {
     
  
  }
  
  })

  const Header= styled('div')({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '70%',
    fontSize: '1.5rem',
    marginTop: '1rem',
     
    color: 'yellow',
   
   
  
  
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })

    const Links= styled('div')({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textDecoration: 'underline',
      marginBottom: '3rem',
      color: 'grey',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        // height: '1.25rem',
    
      },
    
    })
  const FormWrapper= styled('form')({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '70%',
    padding: '2rem',
 
    backgroundColor: 'white',
    color:'black',
    borderRadius: '10px',  
  
  
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })
 
    const NameWrapper= styled('div')({
      position: 'absolute',
      bottom: 0,
   
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'grey',
      
      height: '1rem', 
      padding: '1rem 0 1rem 1rem',
      width: 'calc(100% - 1rem)',
      
      
      [theme.breakpoints.down('sm')]: {
        // height: '1.25rem',
    
      },
    
    })

    const StyledButton= styled(Button)({
      backgroundColor: 'white',
      border: '1px solid #E6E7E8',
      color: chitBurgandyDull,
      margin: '0 8px',
      padding: ' 0 1rem',
      height: '1.5rem',
      fontSize: '.8rem',
      marginTop: '2rem',

      alignSelf: 'center',
      '&:hover' :{
        backgroundColor: lightGrey
      }
    
    })


    

const Content= styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '100%',
  border: '1px solid grey',
 
   
  // borderLeft: '1px solid #E6E7E8',
 
   
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '50%',
  margin: '.25rem',
  padding: '1rem',
  borderRadius: '5px',
backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const ComponentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledInput= styled('input')({

  width: '50%',
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')] : {
     
  
  }
  
  })

  const StyledLabel= styled('label')({

    width: '100%',
    fontSize: '.9rem',
    color: 'grey',
  
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })

    const StyledTextField= styled('textarea')({

      width: '100%',
      fontSize: '1.1rem',
      height: '8rem',
    
      [theme.breakpoints.down('sm')] : {
         
      
      }
      
      })