import React, {useEffect, useState, useRef } from 'react';

import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch} from 'react-redux'


import {chitBurgandy, chitBurgandyDull, lightGrey, mediumGrey, mediumLightGrey, veryLightGrey} from '../../styles/colors'


import { selectLogin, updateLogin } from '../../app/redux/loginSlice';


import GoldCoin from '../../images/gold_kindness.svg'
import Button from '@mui/material/Button'; 
import { styled, createTheme  } from "@mui/material/styles"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 
const theme = createTheme(); // allows use of mui theme in styled component


// let access = ['chitgit']

let access = [ 'lenpepsi', 'Lenpepsi', 'LenPepsi', 'lenpepsi', 'len pepsi', 'Len pepsi', 'Len Pepsi' , 'len Pepsi']




// =======================================================

const Landing = () => {
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const form = useRef()
  // --- dialog box open and close
  const [open, setOpen] = React.useState(false);
  
  const handleClickAway = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleLogin = (e)=>{
    e.preventDefault()
    
    
        let login = e.target.login.value
        let loginValue = login.toLowerCase()
    
        let exists = access.find(item => item === loginValue);
    
        
      console.log('[ Zobada landing checkIfWordExists] FINAL FINAL ', exists);
    
    
        if(exists){ 
    
          dispatch(updateLogin(loginValue))
          // sessionStorage.setItem("login", "chitgit")
          navigate(`/home`)
    
        console.log('ZobadaLanding - yeah great login', e.target.login.value)
      }else{
        alert('passcode invalid')
        e.target.reset()
      }
      }
  const [showForm, setShowForm] = useState('hide')
  

  const handleClick = ()=>{

    navigate(`/contact`)
  }


  const handleFormClick = ()=>{

    setOpen(true)
  }

console.log('[ Landing in Zobada ] showForm ');

  return (
    <BodyWrapper>

<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">

      </DialogTitle>
      <DialogContent>
  
      </DialogContent>
      <DialogActions>

      <FormWrapper  ref= {form} 
      onSubmit={handleLogin}
      >
        <TitleWrapper> Enter passcode</TitleWrapper>
        
        <ButtonWrapper>
        <input  type="text" name="login" ></input>

<StyledButton   type = 'submit' variant="contained" color="primary">
  Go
</StyledButton>
</ButtonWrapper>
      </FormWrapper>

      </DialogActions>
    </Dialog>

      <NameWrapper>
        Zobada - a personal site for Brad Brous 
        
        </NameWrapper>
      <Wrapper>
     
      <CoinStylePulse>  </CoinStylePulse>
      <CoinStyle src={GoldCoin} alt="Chit Git Logo" 
        onClick = {()=> handleFormClick()}
        className = 'pulse'
      />
  



{/*  
      <ContanctLink
          onClick = {()=> handleFormClick()}
        >Contact me </ContanctLink> */}



      </Wrapper>

    </BodyWrapper>
  );
}

export default Landing

// --------------------------------------------------------

const BodyWrapper= styled('div')({
  display: 'block',
  
  backgroundColor: 'black' ,
  height: '100vh',
 
  fontFamily: 'Lato, sans-serif',
  overflow: 'hidden',
 

  [theme.breakpoints.down('xs')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }
})

const Wrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '80%',
  paddingTop: '20%',
  color: 'white',
  fontSize: '1rem',
 
 
  overflow: 'hidden',


  [theme.breakpoints.down('sm')] : {
     
  
  }
  
  })




    
 

  const CoinStyle= styled('img')({
    position: 'absolute',
    height: '6rem',
    cursor: 'pointer',
  
    

    '&:hover' :{
  
      opacity: '.8'
    }
  
  })

  const CoinStylePulse= styled('div')({
    position: 'absolute',
    borderRadius: '50px',
    // boxShadow: '0px 0px 1px 1px #0000001a ',
    height: '6rem',
    width: '6rem',
    backgroundColor: '#606062',
    cursor: 'pointer',
   
    "@keyframes pulsate": {
      from: {
        opacity: 1,
        transform: "scale(1)"
      },
      to: {
        opacity: 0,
        transform: "scale(1.5)"
      }
    },
    animation: "pulsate 2s infinite ease",
    position: "absolute",
 
  
  })






  const ButtonWrapper= styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    margin: '.75rem',
  
    
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
  })

  const NameWrapper= styled('div')({

 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    borderBottom: '1px solid grey',
    height: '5rem', 
    
    width: '100%',
    
    
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
  })

  const BottomWrapper= styled('div')({
    position: 'absolute',
    top: '5px',
 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    borderBottom: '1px solid grey',
    height: '5rem', 
    
    width: '100%',
    
    
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
  })

  const ContanctLink= styled('div')({
 
 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginToop: '2rem',
    textDecoration: 'underline',
    color: mediumGrey,
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
  })

  const Center = styled('div')({
 
 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginToop: '2rem',
    textDecoration: 'underline',
    color: mediumGrey,
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
  })


  

  const FormWrapper= styled('form')({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
   marginBottom: '2rem',
    color: 'white',
    fontSize: '3rem',
   
  
  
  
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })


  const TitleWrapper= styled('div')({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: '20rem',

    // color: 'white',
    fontSize: '1rem',
    color: chitBurgandy,
   
  
  
  
    [theme.breakpoints.down('sm')] : {
       
    
    }
    
    })

    const StyledButton= styled(Button)({
      backgroundColor: 'white',
      border: '1px solid #E6E7E8',
      color: chitBurgandyDull,
      margin: '1.2rem',
      cursor: 'pointer',
      height: '1.5rem',
      width: '1.5rem',
      borderRadius: '50px',
      fontSize: '.8rem',
  
      '&:hover' :{
        border: '2px solid aqua',
        color: 'black',
        backgroundColor: veryLightGrey,
  
      }
    
    })
